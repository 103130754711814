@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700&display=swap");

html {
  font-size: 62.5%;
}

body > * {
  font-family: "Inter Variable", sans-serif;
}

body {
  font-size: 1.6rem;
  font-weight: 500;
  font-family: "Inter Variable", sans-serif;
  letter-spacing: 0.03rem;
  color: #383838;
}

ul.abb-custom-bullets {
  list-style: none !important;
}

ul.abb-custom-bullets li::before {
  content: "\2022";
  color: #1659bb;
  font-weight: bold;
  display: inline-block;
  width: 26px;
  margin-left: -26px;
}

.abb-custom-scrollbar::-webkit-scrollbar {
  width: 6px;
  height: 7px;
}

.abb-custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #a3a8af;
  border-radius: 50px;
}

.abb-custom-scrollbar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.2);
}

.abb-custom-scrollbar-blue::-webkit-scrollbar {
  width: 6px;
  height: 3px;
}

.abb-custom-scrollbar-blue::-webkit-scrollbar-thumb {
  background-color: #1485ff;
  border-radius: 50px;
}

.abb-custom-scrollbar-blue::-webkit-scrollbar-track {
  box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.2);
}

.abb-custom-scrollbar-gray::-webkit-scrollbar {
  width: 8px;
  height: 7px;
}

.abb-custom-scrollbar-gray::-webkit-scrollbar-thumb {
  background-color: #dcdce3;
  border-radius: 50px;
}

.custom-select-scrollbar::-webkit-scrollbar {
  width: 8px;
  height: 80px;
}

.custom-select-scrollbar::-webkit-scrollbar-track {
  background: transparent;
}

.custom-select-scrollbar::-webkit-scrollbar-thumb {
  background-color: #3b8dd4;
  border-radius: 50px;
}

.react-pdf__Page__textContent {
  display: none;
}

/* @font-face {
  font-family: 'SF-Pro';
  src: local('SF-Pro-Black'), url('./assets/fonts/SF-Pro-Black.otf') format('opentype');
  font-weight: 900;
} */
@font-face {
  font-family: "SF-Pro";
  src:
    local("SF-Pro-Bold"),
    url("./assets/fonts/SF-Pro-Bold.otf") format("opentype");
  font-weight: 900;
}

@font-face {
  font-family: "SF-Pro";
  src:
    local("SF-Pro-Heavy"),
    url("./assets/fonts/SF-Pro-Heavy.otf") format("opentype");
  font-weight: 800;
}

@font-face {
  font-family: "SF-Pro";
  src:
    local("SF-Pro-Light"),
    url("./assets/fonts/SF-Pro-Light.otf") format("opentype");
  font-weight: 400;
}

@font-face {
  font-family: "SF-Pro";
  src:
    local("SF-Pro-Regular"),
    url("./assets/fonts/SF-Pro-Regular.otf") format("opentype");
  font-weight: 500;
}

@font-face {
  font-family: "SF-Pro";
  src:
    local("SF-Pro-Medium"),
    url("./assets/fonts/SF-Pro-Medium.otf") format("opentype");
  font-weight: 600;
}

@font-face {
  font-family: "SF-Pro";
  src:
    local("SF-Pro-Semibold"),
    url("./assets/fonts/SF-Pro-Semibold.otf") format("opentype");
  font-weight: 700;
}

@font-face {
  font-family: "SF-Pro";
  src:
    local("SF-Pro-Thin"),
    url("./assets/fonts/SF-Pro-Thin.otf") format("opentype");
  font-weight: 300;
}

@font-face {
  font-family: "SF-Pro";
  src:
    local("SF-Pro-Ultralight"),
    url("./assets/fonts/SF-Pro-Ultralight.otf") format("opentype");
  font-weight: 200;
}

@font-face {
  font-family: "SF-Pro display";
  src:
    local("SF-Pro-Display-Bold"),
    url("./assets/fonts/SF-Pro-Display-Bold.otf") format("opentype");
  font-weight: 900;
}

@font-face {
  font-family: "SF-Pro display";
  src:
    local("SF-Pro-Display-Heavy"),
    url("./assets/fonts/SF-Pro-Display-Heavy.otf") format("opentype");
  font-weight: 800;
}

@font-face {
  font-family: "SF-Pro display";
  src:
    local("SF-Pro-Display-Light"),
    url("./assets/fonts/SF-Pro-Display-Light.otf") format("opentype");
  font-weight: 400;
}

@font-face {
  font-family: "SF-Pro display";
  src:
    local("SF-Pro-Display-Regular"),
    url("./assets/fonts/SF-Pro-Display-Regular.otf") format("opentype");
  font-weight: 500;
}

@font-face {
  font-family: "SF-Pro display";
  src:
    local("SF-Pro-Display-Medium"),
    url("./assets/fonts/SF-Pro-Display-Medium.otf") format("opentype");
  font-weight: 600;
}

@font-face {
  font-family: "SF-Pro display";
  src:
    local("SF-Pro-Display-Semibold"),
    url("./assets/fonts/SF-Pro-Display-Semibold.otf") format("opentype");
  font-weight: 700;
}

@font-face {
  font-family: "SF-Pro display";
  src:
    local("SF-Pro-Display-Thin"),
    url("./assets/fonts/SF-Pro-Display-Thin.otf") format("opentype");
  font-weight: 300;
}

@font-face {
  font-family: "SF-Pro display";
  src:
    local("SF-Pro-Display-Ultralight"),
    url("./assets/fonts/SF-Pro-Display-Ultralight.otf") format("opentype");
  font-weight: 200;
}

/* remove number input arrow */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.app-wrapper {
  min-height: calc(100vh);
}

button:not(.cursor-default)[disabled] {
  cursor: not-allowed !important;
}

.ReactModal__Overlay {
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.shake-effect {
  animation: shake 0.6s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

.min-h-40 {
  min-height: 40px;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

.hide-native-element {
  border: 0px;
  clip: rect(0px, 0px, 0px, 0px);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0px;
  overflow: hidden;
  white-space: nowrap;
  position: absolute;
}

.slick-dots li.slick-active button:before {
  color: #3b8dd4 !important;
  opacity: 1 !important;
}

.slick-dots li button:before {
  font-size: 9px !important;
}

.slick-dots li {
  width: 10px !important;
}

.slick-dots {
  bottom: -34px !important;
}

/* .slick-list {
  padding: 0 20% 0 0 !important;
}

.slick-slide {
  margin: 0 9px !important;
}

.slick-list {
  margin: 0 -10px !important;
} */

/* .slick-list {
  padding: 0 !important;
}
.slick-slide div {
  width: auto;
  margin-right: 5px;
} */

.slick-slide div {
  margin-right: 12px;
}

.landing .slick-slide div {
  margin-right: 0 !important;
}
/* .landing .slick-dots .slick-active button {
  background-color: #0d0d19;
  width: 32px;
  border-radius: 32px;
  height: 8px;
  padding: 0;
} */

/* .slick-list {
  padding: 0 50px 0 0 !important;
} */

.floating_label {
  top: 0;
  left: 0;
  z-index: 2;
  position: "absolute";
  background-color: "red";
  pointer-events: "none";

  transform-origin: "left top";
}

.pdf-page canvas {
  margin: auto;
}

.pdf-page {
  margin-bottom: 20px;
  box-shadow: -1px 4px 7px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.gradient-text {
  background-color: #1c105c;
  background-image: linear-gradient(89deg, #9559cb 39.44%, #1c105c 99.49%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}

.ml-05 {
  margin-left: 0.5rem;
}
